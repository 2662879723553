import { template as template_bbba215838534c74a6d55a75ca5ee6e9 } from "@ember/template-compiler";
const FKLabel = template_bbba215838534c74a6d55a75ca5ee6e9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
