import { template as template_f1c3722c32b94e8fab288eb166c18ffb } from "@ember/template-compiler";
const FKControlMenuContainer = template_f1c3722c32b94e8fab288eb166c18ffb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
