import { template as template_50effe6dc89e4825b4ce835bda132dfd } from "@ember/template-compiler";
const FKText = template_50effe6dc89e4825b4ce835bda132dfd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
