import { template as template_029ad03fd5ed4afd9d99bcc160d046c6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_029ad03fd5ed4afd9d99bcc160d046c6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
