import { template as template_34ed3315c2ea4553b9602a1f9a828ecf } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_34ed3315c2ea4553b9602a1f9a828ecf(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
